import React, { useEffect } from 'react';
import KnowOurTeam from '../ourteam/KnowOurTeam';
import AOS from 'aos';
import 'aos/dist/aos.css';


const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="background-aboutus">

        <div className="container" >
          <div className="row">
            <div className="col-12">
              <h1 className='fs5-3 white padding_about text-center' >Om oss</h1>
            </div>
          </div>
        </div>

      </div>
      <KnowOurTeam />
      <div className='container my-6 overflow-hidden bg_white' >
        <div id="content-aboutus" className='mb-5'>
          <div class="small-aboutus" >
            <div className='aboutus-our-mission flex-start' >
              <p className='m-0 line_height_about black' >Vår</p>
              <p className='m-0 line_height_about black'  > vision</p>
            </div>
          </div>
          <div class="double" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="600">
            <p className="color_p text_justify" >I en bransch där tekniken ständigt utvecklas och behoven förändras är det avgörande för oss att fokusera på våra kunders bästa intresse. Det innebär att vi erbjuder total transparens i vår kommunikation och analys, och strävar efter att erbjuda den mest effektiva lösningen till den bästa möjliga kostnaden. Vårt åtagande sträcker sig även till att erbjuda kontinuerlig support och service, även efter att affären har genomförts. </p>
          </div>
        </div>
        <div id="content-aboutus-reverse" className='mb-5'  >
          <div class="double" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
            <p className="color_p text_justify" >Vi är stolta över vårt arbete och vår förmåga att skapa långsiktiga och pålitliga affärsrelationer. Genom att konsekvent hålla oss till våra värderingar skapar vi en gedigen affär där du som kund blir vår bästa marknadsförare. </p>

          </div>
          <div class="small-aboutus" >
            <div className='aboutus-our-mission flex-end' >
              <p className='m-0 line_height_about black' >Vår</p>
              <p className='m-0 line_height_about black' >styrka</p>
            </div>
          </div>
        </div>
        <div id="content-aboutus" className=''>
          <div class="small-aboutus" >
            <div className='aboutus-our-mission flex-start' >
              <p className='m-0 line_height_about black' >Miljöansvar</p>

            </div>
          </div>
          <div class="double" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="600">
            <p className="color_p text_justify" >På Omni Telecom tar vi även ansvar för miljön och strävar efter att minimera vår påverkan genom att arbeta så digitalt som möjligt. Genom att använda tekniken till vår fördel möjliggör vi nationellt arbete och minskar behovet av fysiska möten, trots att vi är baserade i södra Sverige sträcker vårt arbete sig över hela landet.</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AboutUs
