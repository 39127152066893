import React, { useRef, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {  RxHamburgerMenu } from "react-icons/rx";
import { IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import logo from '../assets/logo-omni-photo.png';

const Navbar = () => {
    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const menuRef = useRef(null);
    const searchRef = useRef(null);

    const toggleMenu = () => {
        setShow(!show);
    };

    const handleClose = () => {
        setShow(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const toggleSearch = () => {
        setShowSearch(!showSearch);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowSearch(false);
        }
    };

    const handleScroll = () => {
        setShowSearch(false);
    };

    return (
        <>
            <nav>
                <NavLink to="/">
                    <img className='company-logo' src={logo} alt="Company Logo" />
                </NavLink>
                <div>
                    <ul id='navbar' className={show ? '#navbar active' : "#navbar"}>
                        <div onClick={toggleMenu} className='cross-icon'>
                            <IoMdClose />
                        </div>
                       
                        <li className='padding-mobile-nav text-center d-lg-none'><img className='company-logo' src={logo} alt="Company Logo" /></li>
                        <li onClick={() => { handleClose(); scrollToTop() }} className='active' ><NavLink to='/'>Hem</NavLink></li>
                        <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/aboutUs'>Om oss</NavLink> </li>
                      
                        <li className='hide-cls_nav'><NavLink  >Tjänster<IoMdArrowDropdown className='fs10' /></NavLink>
                            <ul className='sub-menu'  >
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services' >Företagstelefoni</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-cloud'>Molnväxel</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-car'> Körjournal</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-document'> Dokumenthantering</NavLink></li>
                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-crm'>Arbetsordersystem / CRM</NavLink></li>

                            </ul>
                        </li>
                        <li onClick={() => { handleClose(); scrollToTop() }}><NavLink to='/contactUs' >Kontakta oss </NavLink></li>
                       
                    </ul>
                </div>
                <div id='mobile' onClick={toggleMenu}>
                    <span>
                        <RxHamburgerMenu />
                    </span>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
