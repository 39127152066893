

import Home from "./components/HomePage/Home";
import AboutUs from "./components/aboutus/AboutUs";
import ContactUs from "./components/contact/ContactUs";
import KnowOurTeam from "./components/ourteam/KnowOurTeam";
import AdvisoryServices from "./components/services/AdvisoryServices";
import OfficerServices from "./components/services/OfficerServices";
import ProcessServices from "./components/services/ProcessServices";
import SecurityServices from "./components/services/SecurityServices";
import ServicePage from "./components/services/ServicePage";

export const route = [
    {
        id: 1,
        name: "Home",
        path: "/",
        component: <Home />,
    },
    {
        id: 2,
        name: "About Us",
        path: "/aboutUs",
        component: <AboutUs />,
    },
    {
        id: 3,
        name: "Contact Us",
        path: "/contactUs",
        component: <ContactUs />,
    },
    {
        id: 4,
        name: "Know Our Team",
        path: "/know-our-team",
        component: <KnowOurTeam />,
    },
   
    {
        id: 5,
        name: "Service page",
        path: "/services",
        component: <ServicePage />,
    },
    
    {
        id: 6,
        name: "Service page1",
        path: "/services-cloud",
        component: <OfficerServices />,
    },
    {
        id: 7,
        name: "Service page2",
        path: "/services-car",
        component: <AdvisoryServices />,
    },
    {
        id: 8,
        name: "Service page3",
        path: "/services-document",
        component: <ProcessServices />,
    },
    {
        id: 9,
        name: "Service page4",
        path: "/services-crm",
        component: <SecurityServices />,
    },
    
   
]