import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import PrincipleModal from '../HomePage/PrincipleModal';

const OfficerServices = () => {
    useEffect(() => {
        AOS.init();
      }, []);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    // const scrollToTop = () => {
    //     window.scrollTo(0, 0);
    // };
    useEffect(() => {
        scrollToTop();
    }, []);
    const scrollToTop = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    
    return (
        <div className='minh65'>
            <div className="background-service-home1">
                <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">Molnväxel</h1>
            </div>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-md-8 col-lg-9 mb-4'>
                        <h2 class="black  mb-3">Molnväxel  </h2>
                        <p className=' blackp' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400">Att knyta ihop företagets telefoni genom en mobil växel är idag en självklarhet för många. Man får helt enkelt kontroll på vad som händer med inkommande samtal, vart dem ska och när, att man inte riskerar att missa ett samtal från kund som både kan innebära missad försäljning eller missad support. Hur man gör det är dock avgörande. </p>
                        <p className=' blackp' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400">Dom moderna växelplattformarna som är utvecklade av fristående aktörer har tagit funktionaliteten till en helt ny nivå. Numera kan man samla all sin kommunikation i samma plattform som sin växel som tex chattkanaler, office365 synkronisering och videokonferens. </p>
                        <p className=' blackp' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400">Vi samarbetar främst med Lynes Technology då vi anser dessa vara marknadens absolut bästa aktör utifrån ett kundperspektiv. </p>
                        <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400" className='blackp mb-1 marker_data' data-bs-toggle="modal" data-bs-target="#modalService"><Link className='blackp'><MdOutlineKeyboardDoubleArrowRight className="arrow-service blackp" /> Kontakta oss så berättar vi mer </Link></p>
                    </div>
                    <div class="col-md-4 col-lg-3 mb-4">
                        <div className='card card_shodow1 border-0 h-100'>
                            <h4 className='card-header bg_data_ul py-3 text-center'>Tjänster</h4>
                            <div className='card-body'>
                                <ul className='mb-0 ps-0'>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'><MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Företagstelefoni </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-car' className='clr_data_list'> <MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Körjournal</Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-document' className='clr_data_list'> <MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Dokumenthantering </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-crm' className='clr_data_list'><MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Arbetsordersystem / CRM </Link></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <PrincipleModal />
        </div>
    )
}

export default OfficerServices
