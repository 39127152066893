import React, { useState } from 'react';
import Swal from 'sweetalert2';

const PrincipleModal = () => {

    const [name, setName] = useState('');
    const [business_name, setBusiness_name] = useState('');
    const [phone, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [numberError, setNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleNumberChange = (e) => {
        const inputValue = e.target.value;
        setNumber(inputValue);

        // Validate number
        if (isNaN(inputValue)) {
            setNumberError('Ange ett telefonnummer');
        } else {
            setNumberError('');
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(inputValue)) {
            setEmailError('Vänligen ange e-post');
        } else {
            setEmailError('');
        }
    };

   

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!numberError && !emailError && phone && email) {
            setLoading(true); // Show loader

            setNumber('');
            setEmail('');

            // Make HTTP POST request to the PHP file
            fetch('https://development81.shrinkcom.com/smtp_mailsss/send-mail-omni.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    phone: phone,
                    email: email,
                    subject:"Booking",
                }),
            })
                .then(response => {
                    setLoading(false); // Hide loader

                    if (response.ok) {
                        // Handle successful response
                        return response.json();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(data => {
                    // Handle data if needed
                    Swal.fire({
                        text: 'Tack! Vi återkommer snarast möjligt',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#1e2025',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                })
                .catch(error => {
                    setLoading(false); // Hide loader

                    // Handle error
                    console.error('There was a problem with the fetch operation:', error);
                });

        } else {
            if (!phone) {
                setNumberError('Ange ett telefonnummer');
            }
            if (!email) {
                setEmailError('Vänligen ange e-post');
            }
        }
    };
    
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleBusinessChange = (event) => {
        setBusiness_name(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    return (
        <div>
            <div className="modal  fade z_index_popup" id="modalService" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content bg_black position-relative">
                        <div className="modal-header border-0">
                            <h1 className="modal-title fs-5 white" > BOKA EN DEMO </h1>
                            <button type="button" className="btn-close modal_close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3 white">
                            <p>Vad kul att du vill veta mer om våra tjänster. Skriv in dina kontaktuppgifter här nedan så återkommer vi till dig så fort som möjligt.</p>
                            {loading && (
                                <div className='position_loder'>
                                    <div className="spinner-border text-white" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    {/* <input type="text" className="form-control" id="fullName" placeholder="Ditt namn" /> */}
                                    <input type="text" class="form-control" id="fullName" placeholder="Namn" value={name}
                                            onChange={handleNameChange}
                                        />
                                </div>
                                <div className='mb-3'>
                                    {/* <input type="email" id="email" placeholder="E-post" className="form-control" value={email} onChange={handleEmailChange} /> */}
                                    <input type="email" id="email" placeholder="E-post" className="form-control" value={email} onChange={handleEmailChange} />
                                    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                                </div>
                                <div className='mb-3'>
                                    {/* <input type="number" className="form-control" placeholder="Ditt telefonnummer" value={number} onChange={handleNumberChange} /> */}
                                    <input type="number" className="form-control" placeholder="Telefon" value={phone} onChange={handleNumberChange} />
                                    {numberError && <div style={{ color: 'red' }}>{numberError}</div>}
                                </div>
                                <div className=''>
                                    <button type="submit" className="btn btn-warning">Skicka</button>
                                </div>
                            </form>
                           

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}



export default PrincipleModal
