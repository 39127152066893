import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import img from '../../assets/service1.svg'
import img1 from '../../assets/service-mo.svg'
import img2 from '../../assets/service3.png'
import img3 from '../../assets/service4.png'
import img4 from '../../assets/service5.svg'
import 'swiper/css';
import 'swiper/css/navigation';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import PrincipleModal from './PrincipleModal';



const ServiceCard = () => {

    // const scrollToTop = () => {
    //     window.scrollTo(0, 0)
    // }
    useEffect(() => {
        scrollToTop();
    }, []);

    const scrollToTop = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };



    return (
        <div className='bg_service overflow-hidden'>
            <div className='container pb-5' >
                <div className='mb-4'>
                    <h1 className='text-center pb-lg-3' >VI HJÄLPER ER MED</h1>
                </div>
                <div className='row align-items-center justify-content-center p-0 pt-3 ' >
                    <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                        <div className='service-card-border py-3 p-3 h-100' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="service-card-icon mx-auto my-3" >
                                <img src={img} className='img-fluid img_service' />
                            </div>
                            <div >
                                <h4 className=' white' >Företagstelefoni</h4>
                                <p className='white line3_content mb-2'> Vi är experter på allt som ingår i begreppet mobiltelefoni för företag. Mångårig erfarenhet från både operatörsledet men även som återförsäljare tillsammans med en ödmjuk inställning till hur en affärsmässig relation ska se ut där ett samarbete utan två vinnare inte är värt kallas varken samarbete eller affär, gör oss till en av dem absolut bästa på det vi gör.</p>
                                <p className='arrow_service' data-bs-toggle="modal" data-bs-target="#modalService"><MdOutlineKeyboardDoubleArrowRight className="arrow" /><b>Kontakta oss så berättar vi mer</b></p>
                                <Link to='/services'  ><button type="button" class="btn py-1 btn-outline-light rounded-1 mb-3">läs mer</button></Link>
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                        <div className='service-card-border py-3 p-3 h-100' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="600">
                            <div className="service-card-icon mx-auto my-3" >
                                <img src={img1} className='img-fluid img_service' />
                            </div>
                            <div >
                                <h4 className=' white' >Molnväxel</h4>
                                <p className='white line3_content mb-2'> Att knyta ihop företagets telefoni genom en mobil växel är idag en självklarhet för många. Man får helt enkelt kontroll på vad som händer med inkommande samtal, vart dem ska och när, att man inte riskerar att missa ett samtal från kund som både kan innebära missad försäljning eller missad support. Hur man gör det är dock avgörande. </p>
                                <p className='arrow_service' data-bs-toggle="modal" data-bs-target="#modalService"><MdOutlineKeyboardDoubleArrowRight className="arrow" /><b>Kontakta oss så berättar vi mer</b></p>
                                <Link to='/services-cloud'  ><button type="button" class="btn py-1 btn-outline-light rounded-1 mb-3">läs mer</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                        <div className='service-card-border py-3 p-3 h-100' data-aos="fade-left" data-aos-easing="linear" data-aos-duration="600">
                            <div className="service-card-icon mx-auto my-3" >
                                <img src={img2} className='img-fluid ' />
                            </div>
                            <div >
                                <h4 className=' white' >Körjournal</h4>
                                <p className='white line3_content mb-2'> En elektronisk körjournal är det moderna sättet att loggföra resor med företagets fordon vilket numera är ett krav från skatteverket. </p>
                                <p className='arrow_service' data-bs-toggle="modal" data-bs-target="#modalService"><MdOutlineKeyboardDoubleArrowRight className="arrow" /><b>Kontakta oss så berättar vi mer</b></p>
                                <Link to='/services-car'  ><button type="button" class="btn py-1 btn-outline-light rounded-1 mb-3">läs mer</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                        <div className='service-card-border py-3 p-3 h-100' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                            <div className="service-card-icon mx-auto my-3" >
                                <img src={img3} className='img-fluid' />
                            </div>
                            <div >
                                <h4 className=' white' >Dokumenthantering</h4>
                                <p className='white line3_content mb-2'> Med lång erfarenhet av dokumenthantering säkerställer vi att ni har rätt lösning så att ni inte bara sparar pengar, utan också sparar en stor mängd tid och energi samtidigt som effektivisering av utskrifter spar på miljön.  </p>
                                <p className='arrow_service' data-bs-toggle="modal" data-bs-target="#modalService"><MdOutlineKeyboardDoubleArrowRight className="arrow" /><b>Kontakta oss så berättar vi mer</b></p>
                                <Link to='/services-document'  ><button type="button" class="btn py-1 btn-outline-light rounded-1 mb-3">läs mer</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                        <div className='service-card-border py-3 p-3 h-100' data-aos="fade-left" data-aos-easing="linear" data-aos-duration="600">
                            <div className="service-card-icon mx-auto my-3" >
                                <img src={img4} className='img-fluid img_service' />
                            </div>
                            <div >
                                <h4 className=' white' >Arbetsordersystem / CRM</h4>
                                <p className='white line3_content mb-2'>Med vårt arbetsordersystem har du allt du behöver för att sköta arbetsvardagen på ett och samma ställe. Håll koll på dina projekt, din nedlagda arbetstid, dina beställningar och mycket mer. </p>
                                <p className='arrow_service' data-bs-toggle="modal" data-bs-target="#modalService"><MdOutlineKeyboardDoubleArrowRight className="arrow" /><b>Kontakta oss så berättar vi mer</b></p>
                                <Link to='/services-crm'  ><button type="button" class="btn py-1 btn-outline-light rounded-1 mb-3">läs mer</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
              

            </div>
            <PrincipleModal />
        </div>
    )
}

export default ServiceCard
