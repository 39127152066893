import React from 'react'


const KnowOurTeam = () => {
  return (
    <div className='bg_who'>
      <div className="container py-5" >
       
          <h1 className="   text-center mb-4 white" >Vilka är vi?</h1>
          <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
          <p className='text-center'>Omni Telecom är skapat för att bygga värde åt sina kunder, antingen i form av kostnadsbesparingar eller i att förbättra kundens mobila- eller digitala vardag. Med Omni Telecom som partner är det i regel en kombination av både och. Vi är här för att skapa långsiktiga relationer med våra kunder.
          </p>
          <p className='text-center' >För att lyckas med detta i en bransch som vår så finns det enligt oss inget annat än att förbehållslöst se till våra kunders bästa i allt ifrån total transparens i dialogen och analysen, till den faktiska kostnaden kontra vad det är man faktiskt behöver i sin lösning, till supporten efter man blivit kund.</p>
          <p className='text-center' >Vi är stolta över det vi gör då vi vet att om konsekvent utgår från detta så skapar man en långsiktig och gedigen affär där kunden blir vår absolut bästa marknadsförare.

          </p>
          <p className='text-center' > Vi värnar om miljön och arbetar därför så digitalt vi kan med allt från avtalshantering till faktiska möten. I dagsläget är det enbart tekniken som sätter gränserna vilket gör att vi arbetar nationellt. Detta trots att vi utgår från södra Sverige.</p>
          </div>

      
      </div>

    </div>
  )
}

export default KnowOurTeam
