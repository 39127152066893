import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import PrincipleModal from '../HomePage/PrincipleModal';

const ServicePage = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    // const scrollToTop = () => {
    //     window.scrollTo(0, 0);
    // };
    useEffect(() => {
        // Scroll to top when the component mounts
        scrollToTop();
    }, []);

    const scrollToTop = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };
    
    return (
        <div className='minh65'>


            <div className="background-service-home">
                <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">Företagstelefoni</h1>
            </div>
            <div className='container py-5'>
                <div className="row">
                    <div className="col-md-8 col-lg-9 mb-4">
                        <div className='pe-xxl-5'>
                            <h2 class="black  mb-3">Företagstelefoni  </h2>
                            <p className='blackp' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400">Vi är experter på allt som ingår i begreppet mobiltelefoni för företag. Mångårig erfarenhet från både operatörsledet men även som återförsäljare tillsammans med en ödmjuk inställning till hur en affärsmässig relation ska se ut där ett samarbete utan två vinnare inte är värt kallas varken samarbete eller affär, gör oss till en av dem absolut bästa på det vi gör.</p>
                            <p className='blackp ' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400">Vi har starka samarbeten med flera av dem stora operatörerna och utgår alltid från vad som är bäst för våra kunder när vi förhandlar fram avtal med dessa. Detta är en förutsättning för att vi ska kunna leverera en tjänst och produkt som ska ligga till grund för en lång affärsmässig relation vilket är en av Omni Telecoms viktigaste grunder.</p>
                            <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400" className='blackp mb-1 marker_data' data-bs-toggle="modal" data-bs-target="#modalService"><Link className='blackp'><MdOutlineKeyboardDoubleArrowRight className="arrow-service blackp" /> Kontakta oss så berättar vi mer </Link></p>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-3 mb-4">
                        <div className='card card_shodow1 border-0 h-100'>
                            <h4 className='card-header bg_data_ul py-3 text-center'>Tjänster</h4>
                            <div className='card-body'>
                                <ul className='mb-0 ps-0'>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-cloud' className='clr_data_list'><MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Molnväxel </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-car' className='clr_data_list'> <MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Körjournal</Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-document' className='clr_data_list'> <MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Dokumenthantering </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-crm' className='clr_data_list'><MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Arbetsordersystem / CRM </Link></li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <PrincipleModal />
        </div>
    )
}

export default ServicePage

