import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import PrincipleModal from '../HomePage/PrincipleModal';

const ProcessServices = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  // const scrollToTop = () => {
  //   window.scrollTo(0, 0);
  // };
  useEffect(() => {
    scrollToTop();
}, []);
  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
};

  return (
    <div className='minh65'>
      <div className="background-service-home3">
        <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">Dokumenthantering
        </h1>
      </div>
      <div className='container py-5'>

        <div className='row'>
          <div className='col-md-8 col-lg-9 mb-4'>
            <div className='pe-xxl-5'>
              <h2 class="black  mb-3">Dokumenthantering
              </h2>
              <p className='blackp' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400">Med lång erfarenhet av dokumenthantering säkerställer vi att ni har rätt lösning så att ni inte bara sparar pengar, utan också sparar en stor mängd tid och energi samtidigt som effektivisering av utskrifter spar på miljön. </p>
              <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="400" className='blackp mb-1 marker_data' data-bs-toggle="modal" data-bs-target="#modalService"><Link className='blackp'><MdOutlineKeyboardDoubleArrowRight className="arrow-service blackp" /> Kontakta oss så berättar vi mer </Link></p>
            </div>
          </div>
          <div class="col-md-4 col-lg-3 mb-4">
            <div className='card card_shodow1 border-0 h-100'>
              <h4 className='card-header bg_data_ul py-3 text-center'>Tjänster</h4>
              <div className='card-body'>
                <ul className='mb-0 ps-0'>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'><MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Företagstelefoni </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-cloud' className='clr_data_list'> <MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Molnväxel</Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-car' className='clr_data_list'> <MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Körjournal </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-crm' className='clr_data_list'><MdOutlineKeyboardDoubleArrowRight className="arrow-service" /> Arbetsordersystem / CRM </Link></li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrincipleModal />
    </div>
  )
}

export default ProcessServices
